import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_g = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - G'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>G</h2>
     <LatQuotesIntro />
    
     <p><b>Gaudeamus igitur (iuvenes dum sumus)</b> - Therefore, let us rejoice. (while we are young)</p>
<p><b>Genius loci</b> - The guardian spirit of the place</p>
<p><b>Gens togata</b> - The toga-clad race; the romans</p>
<p><b>Genus irritabile vatum</b> - The irritable race of poets. (Horace)</p>
<p><b>Gladiator in arena consilium capit</b> - The gladiator is formulating his plan in the arena (i.e., too late) (Seneca)</p>
<p><b>Gloria filiorum patres</b> - The glory of sons is their fathers</p>
<p><b>Gloria in excelsis deo</b> - Glory to God in the highest</p>
<p><b>Gloria Patri</b> - Glory to the Father</p>
<p><b>Gloria virtutis umbra</b> - Glory (is) the shadow of virtue</p>
<p><b>Gloria</b> - Glory</p>
<p><b>Gloriosum est iniurias oblivisci</b> - It is glorious to forget the injustice</p>
<p><b>Graeca sunt, non leguntur</b> - It is Greek, not for reading</p>
<p><b>Graecia capta ferum victorem cepit</b> - Captive Greece conquered her savage victor. (Horace)</p>
<p><b>Gratia placenti</b> - For the sake of pleasing</p>
<p><b>Graviora manent</b> - Greater dangers await</p>
<p><b>Gutta cavat lapidem, non vi sed saepe cadendo</b> - The drop excavates the stone, not with force but by falling often. (Ovid)</p>

   </Layout>
  )
}

export default LatQuotes_g
